import { UserOutlined } from '@ant-design/icons';
import { Menu, Select } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { hasPermissions, Permission } from '../dtos/user.dto';
import { useAuth } from '../services/auth/auth.service';
import { defaultLocale } from '../services/localization/localization.service';
import { ResetPassword, ResetPasswordModalState } from './pages/user-management/ResetPassword';
import { useBranchInfo } from '../services/editor/editor.service';
import { getBranchNameFromUrl } from '../utils/UrlUtils';
import Loader from '../components/loader/Loader';
import { useMetrics } from '../services/metrics/MetricsProvider';
import { NavigationMetricEvents } from './AppMetricEvents';

const Navigation: FC = () => {
  const NAVIGATION_ITEMS_TO_DISABLE_WHEN_SAML = ['user-management'];
  const location = useLocation();
  const { metrics } = useMetrics();
  const { i18n } = useTranslation(undefined, { useSuspense: false });
  const auth = useAuth();
  if (!auth) {
    return (
      <Loader />
    );
  }
  const hasPermission = hasPermissions(auth.user);
  const [passwordModal, setPasswordModal] = useState<ResetPasswordModalState>();
  const [locale, setLocale] = useState<string>(defaultLocale);

  // This is to provide link back to the branch being worked on.
  // Parse private branch from URL, get the private branch info and link to parent branch in the branches page.
  const branchNameFromUrl = getBranchNameFromUrl();
  const { data: urlBranch } = useBranchInfo(branchNameFromUrl);

  const changeLocale = (loc: string) => {
    metrics.onTrackEvent(NavigationMetricEvents.LocaleSelectionChanged, { locale: loc });
    setLocale(loc);
    i18n.changeLanguage(loc);
  };

  const openPassword = (e: any) => {
    if (!e.ky || e.key === 'Enter') {
      metrics.onTrackEvent(NavigationMetricEvents.ChangePasswordButtonClick);
      passwordModal?.open({ resetUserPasswordParams: { username: auth.user?.username || '' } });
    }
  };

  const branchesLink = () => {
    if (urlBranch) {
      if (urlBranch.isPublic) {
        return `branches/${urlBranch?.name}`;
      } else {
        return `branches/${urlBranch?.parent}`;
      }
    } else {
      return 'branches';
    }
  };

  const onEditorButtonClick = () => metrics.onTrackEvent(NavigationMetricEvents.EditorButtonClick);
  const onShellButtonClick = () => metrics.onTrackEvent(NavigationMetricEvents.ShellButtonClick);
  const onHistoryButtonClick = () => metrics.onTrackEvent(NavigationMetricEvents.HistoryButtonClick);
  const onUserManagementButtonClick = () => metrics.onTrackEvent(NavigationMetricEvents.UserManagementButtonClick);
  const onUserGuideButtonClick = () => metrics.onTrackEvent(NavigationMetricEvents.UserGuideButtonClick);
  const onLogoutButtonClick = () => {
    metrics.onTrackEvent(NavigationMetricEvents.LogoutButtonClick);
    auth.signout();
  };

  const createChildElementsForUser = () => {
    if (auth.user?.samlUser) {
      return [{
        key: 'logout',
        label: (<Link to="/" onClick={onLogoutButtonClick}>Log Out</Link>),
      }];
    } else {
      return [{
        key: 'reset-password',
        label: (<span role="button" tabIndex={0} onClick={openPassword} onKeyUp={openPassword}>Reset Password</span>),
      }, {
        key: 'logout',
        label: (<Link to="/" onClick={onLogoutButtonClick}>Log Out</Link>),
      }];
    }
  };

  let items: (ItemType & { permissions?: Permission[] })[] = [{
    key: 'branches',
    label: (<Link onClick={onEditorButtonClick} to={branchesLink()}>Enquiry Editor</Link>),
    permissions: [Permission.CanUseEditor],
  },
  {
    key: 'shell',
    label: (<Link onClick={onShellButtonClick} to="shell">Enquiry Shell</Link>),
    permissions: [Permission.CanUseEngineShell],
  }, {
    key: 'history',
    label: (<Link onClick={onHistoryButtonClick} to="history">Enquiry History</Link>),
    permissions: [Permission.CanUseEngineHistory],
  },
  {
    key: 'user-management',
    label: (<Link onClick={onUserManagementButtonClick} to="user-management">User Management</Link>),
    permissions: [Permission.CanAdministerUsers],
  }, {
    key: 'user-guide',
    label: (<a onClick={onUserGuideButtonClick} href="https://underwriteme.atlassian.net/wiki/spaces/DOCS/overview" target="_blank" rel="noopener noreferrer">Online User Guide</a>),
    permissions: [Permission.CanSeeUserGuide],
  }, {
    key: 'padding',
    label: (<span style={{ marginLeft: 'auto' }} />),
  },
  {
    key: 'user',
    icon: (<UserOutlined />),
    label: auth.user?.username,
    children: createChildElementsForUser(),
  }, {
    key: 'locale',
    label: (<Select
      value={locale}
      onSelect={changeLocale}
      options={i18n.languages.map((lang) => ({ label: lang, value: lang }))}
    />),
  }];

  if (auth.user?.samlUser) {
    items = items.filter((item) => !NAVIGATION_ITEMS_TO_DISABLE_WHEN_SAML.some((itemToBeDisabled) => itemToBeDisabled === item.key));
  }

  return (
    auth.user && (
      <>
        <Menu
          className="global-nav"
          selectedKeys={[location.pathname.split('/')[1]]}
          mode="horizontal"
          style={{
            width: '100%',
            marginLeft: 50,
            backgroundColor: 'transparent',
            color: '#fff',
            borderBottom: 'none',
          }}
          forceSubMenuRender
          items={items.filter(((item) => !item.permissions || hasPermission(...item.permissions)))}
        />
        <ResetPassword onInitModal={setPasswordModal} />
      </>
    )
  );
};

export default Navigation;
