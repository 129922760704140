// Format is /editor/privateBranch1 /editor/privateBranch1/{asset}/{assetName}
// Returns the private branch name if matches
export const getBranchNameFromUrl = (): string | undefined => {
  const regex = /\/editor\/([^\\/]+)(\/.*)?/g;
  const result = regex.exec(window.location.pathname);
  if (result) {
    return result[1];
  } else {
    return undefined;
  }
};
