import React, {
  FC,
  Suspense,
} from 'react';
import { ConfigProvider } from 'antd';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  Route, BrowserRouter, Routes,
} from 'react-router-dom';
import { enableMapSet } from 'immer';
import axios from 'axios';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import ErrorHandler from '../components/error-handler/ErrorHandler';
import Loader from '../components/loader/Loader';
import Layout from '../components/layout/Layout';
import Navigation from './Navigation';

import './App.less';
import { AuthGuard, AuthProvider } from '../services/auth/auth.service';
import { Permission } from '../dtos/user.dto';
import { ErrorPage } from '../components/error-page/ErrorPage';
import { MetricsProvider } from '../services/metrics/MetricsProvider';
import { CookieProvider } from '../services/cookie/CookieProvider';
import MetricsLoader from '../services/metrics/MetricsLoader';
import { useMonitoring } from '../services/monitoring/MonitoringProvider';
import MonitoringLoader from '../services/monitoring/MonitoringLoader';

axios.interceptors.response.use((response) =>
  /*
   TODO disabling this function for now as it's a specific behaviour for the enquiry-shell and history which is now
    causing issues due to the vast number of responses the editor is returning. The shell and history will be disabled
    for initial release so do not need this for now. Will need to rework this function later.
   */
  // response.data = parseDate(response.data);
// eslint-disable-next-line implicit-arrow-linebreak
  response);

enableMapSet();

const Branches = React.lazy(() => import('./pages/branches/Branches'));
const Shell = React.lazy(() => import('./pages/shell/Shell'));
const History = React.lazy(() => import('./pages/history/History'));
const UserManagement = React.lazy(() => import('./pages/user-management/UserManagement'));
const ConsentManager = React.lazy(() => import('./pages/consent/ConsentManager'));
const Editor = React.lazy(() => import('./pages/editor/Editor'));
const Bucket = React.lazy(() => import('./pages/editor/bucket/Bucket'));
const Locale = React.lazy(() => import('./pages/editor/locale/Locale'));
const Question = React.lazy(() => import('./pages/editor/question/Question'));
const Options = React.lazy(() => import('./pages/editor/options/Options'));
const PriorEnquiryChannel = React.lazy(() => import('./pages/editor/prior-enquiry-channel/PriorEnquiryChannel'));
const Line = React.lazy(() => import('./pages/editor/line/Line'));
const HomeRouter = React.lazy(() => import('./HomeRouter'));

const App: FC = (): React.ReactElement => {
  const { connector } = useMonitoring();
  const {
    error, logout, isAuthenticated,
  } = useAuth0();

  if (error || !isAuthenticated) {
    if (isAuthenticated) logout();
    return (<ErrorPage />);
  }

  return (
    <ErrorHandler
      onError={(error, info) => {
        if (connector) {
          connector.addError(error, info);
        }
        return true;
      }}
      errorFallback={ErrorPage}
    >

      <ConfigProvider virtual={process.env.NODE_ENV !== 'development'}>
        <MetricsProvider>
          <AuthProvider>
            <CookieProvider>
              <MonitoringLoader />
              <MetricsLoader />
              <ReactQueryDevtools />
              <BrowserRouter>
                <Layout
                  className="history"
                >
                  <Navigation key="header" />
                  <Suspense
                    fallback={<Loader />}
                    key="content"
                  >
                    <Routes>
                      <Route path="/branches" element={<AuthGuard permissions={[Permission.CanUseEditor]}><Branches /></AuthGuard>} />
                      <Route path="/branches/:branchName" element={<AuthGuard permissions={[Permission.CanUseEditor]}><Branches /></AuthGuard>} />
                      <Route path="/branches/:branchName/:section" element={<AuthGuard permissions={[Permission.CanUseEditor]}><Branches /></AuthGuard>} />
                      <Route path="/history" element={<AuthGuard permissions={[Permission.CanUseEngineHistory]}><History /></AuthGuard>} />
                      <Route path="/shell" element={<AuthGuard permissions={[Permission.CanUseEngineShell]}><Shell /></AuthGuard>} />
                      <Route path="/user-management" element={<AuthGuard permissions={[Permission.CanAdministerUsers]}><UserManagement /></AuthGuard>} />
                      <Route path="/editor/:branchName" element={<AuthGuard><Editor /></AuthGuard>}>
                        <Route path="bucket" element={<Bucket />} />
                        <Route path="bucket/:bucketName" element={<Bucket />} />
                        <Route path="option" element={<Options />} />
                        <Route path="option/:optionListName" element={<Options />} />
                        <Route path="question" element={<Question />} />
                        <Route path="question/:questionName" element={<Question />} />
                        <Route path="locale" element={<Locale />} />
                        <Route path="locale/:localeName" element={<Locale />} />
                        <Route path="prior-enquiry-channel" element={<PriorEnquiryChannel />} />
                        <Route path="prior-enquiry-channel/:priorEnquiryName" element={<PriorEnquiryChannel />} />
                        <Route path="enquiry-line" element={<Line />} />
                        <Route path="enquiry-line/:lineName" element={<Line />} />
                        <Route path="enquiry-line/:lineName/question" element={<Question />} />
                        <Route path="enquiry-line/:lineName/question/:questionName" element={<Question />} />
                        <Route path="wrap-up-line" element={<Line isWrapUp />} />
                        <Route path="wrap-up-line/:lineName" element={<Line isWrapUp />} />
                        <Route path="wrap-up-line/:lineName/question" element={<Question />} />
                        <Route path="wrap-up-line/:lineName/question/:questionName" element={<Question />} />
                        <Route path="root-enquiry-line" element={<Line isRoot />} />
                        <Route path="root-enquiry-line/:lineName" element={<Line isRoot />} />
                        <Route path="root-enquiry-line/:lineName/question" element={<Question />} />
                        <Route path="root-enquiry-line/:lineName/question/:questionName" element={<Question />} />
                      </Route>
                      <Route path="/consent-management" element={<AuthGuard><ConsentManager /></AuthGuard>} />
                      {/* Need to wrap in AuthGuard in order to force the AuthContext to be populated which is used by the HomeRouter */}
                      <Route
                        path="*"
                        element={<AuthGuard><HomeRouter /></AuthGuard>}
                      />
                    </Routes>
                  </Suspense>
                </Layout>
              </BrowserRouter>
            </CookieProvider>
          </AuthProvider>
        </MetricsProvider>
      </ConfigProvider>

    </ErrorHandler>
  );
};

export default withAuthenticationRequired(App, {});
