export interface EnquiryHistory {
  enquiryId: string;
  snapshots: EnquiryHistorySnapshot[];
}

export interface EnquiryHistorySnapshot {
  snapshotDate: Date;
  enquiry: Enquiry;
  answerTimeline: QuestionHistory[];
  priorEnquiries: ChanneledEnquiry[];
  subsequentEnquiries: ChanneledEnquiry[];
}

export interface ChanneledEnquiry {
  channel: string;
  enquiryId: string;
}

export interface QuestionHistory {
  answers: string[];
  dateAnswered: Date;
  locale: string;
  questionPath: string;
  username: string;
}

export interface Enquiry {
  allAnswers: Record<string, string[]>;
  availableLocales: string[];
  branch: string;
  buckets: EnquiryBucket[];
  effectiveDate: Date;
  enquiryId: string;
  isCloseable: boolean;
  isOpen: boolean;
  isSatisfied: boolean;
  isVoid: boolean;
  locale: string;
  sections: EnquirySection[];
  tag: string;
  debugInfo?: EnquiryDebugInfo;
}

export interface EnquirySection {
  name: string;
  isSatisfied: boolean;
  hasQuestions: boolean;
  enquiryLines: EnquiryLine[];
}

export interface EnquiryLine {
  name: string;
  path: string;
  alias: string;
  section: string;
  preamble: string;
  rawSection: string;
  rawPreamble: string;
  isSatisfied: boolean;
  hasQuestions: boolean;
  isWrapUpLine: boolean;
  isGlobal: boolean;
  isRoot: boolean;
  isForced: boolean;
  tags: string[];
  triggers: string[];
  triggerQuestions: Record<string, any>;
  questions: Question[];
  buckets: EnquiryBucket[];
  staticallyTriggeredLines: string[];
}

export interface EnquiryBucket {
  type: BucketType;
  name: string;
  contributions: BucketContribution[];
  min?: BucketContribution;
  max?: BucketContribution;
  sum?: BucketContribution;
}

export interface BucketContribution {
  value: number | string;
  lowValue: number | string | undefined;
  duration: string | undefined;
  sources: string[];
}

export interface Question {
  name: string;
  path: string;
  locale: string;
  hasAnswer: boolean;
  isSatisfied: boolean;
  triggeredLines: string[];
  answers: string[];
  validationErrors: Record<string, string[]>;
  definition: Definition;
}

export interface Definition {
  type: QuestionType;
  name: string;
  text: string;
  helpText: string;
  isMultiValued?: boolean;
  isStrict?: boolean;
  minSearchChars?: number;
  tags: string[];
  optionListName: string[];
  includedCategories: string[];
  excludedCategories: string[];
  options: string[];
  optionTags: { [optionName: string]: string[] };
  maxLength?: number;
  lowerBound?: number;
  upperBound?: number;
}

export interface EnquiryDebugInfo {
  nonBranchingLines: string[];
  nonTriggeringLines: string[];
  noDataLines: string[];
}

export interface NonDefEnquiry extends Omit<Enquiry, 'sections'> {
  sections: NonDefEnquirySection[];
}

export interface NonDefEnquirySection extends Omit<EnquirySection, 'enquiryLines'> {
  enquiryLines: NonDefEnquiryLine[];
}

export interface NonDefEnquiryLine extends Omit<EnquiryLine, 'questions'> {
  questions: NonDefQuestion[];
}

export interface NonDefQuestion extends Omit<Question, 'definition'> {
  options?: string[];
  optionTags?: { [optionName: string]: string[] }
}

export enum QuestionType {
  INTEGER = 'INTEGER',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  DATE = 'DATE',
  FUTURE_DATE = 'FUTURE_DATE',
  PAST_DATE = 'PAST_DATE',
  OPTION_LIST = 'OPTION_LIST',
  OPTION_GROUP = 'OPTION_GROUP',
  OPTION_BACKED = 'OPTION_BACKED',
}

export enum BucketType {
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  OPTION = 'OPTION',
  ENQUIRY_LINE = 'ENQUIRY_LINE',
}

export type Collation = 'DEPTH_FIRST_ORDER' | 'BREADTH_FIRST_ORDER';

export interface EnquiryAnswers {
  [path: string]: string[];
}

export interface Environment {
  id: string;
  name: string;
}

export interface Branch {
  isDefault?: boolean;
  name: string;
  tags: Tag[];
}

export interface Tag {
  name: string;
  locales: Locale[];
}

export interface Locale {
  name: string;
  languageCode: string;
  countryCode: string;
  dateFormatPreference: string;
  description: string;
  isDefault?: boolean;
}

export interface Deployment {
  id: number;
  branch: string;
  tag: string;
  ordinal: number;
  date: Date;
  effectiveDate: Date;
  isVoid: boolean;
}

export interface OptionList {
  name: string;
  options: OptionListOption[];
}

export interface OptionListOption {
  name: string;
  text: string;
  aliases: string[];
  categories: string[];
  tags: string[];
  attributes: Record<string, string>;
}

export interface RawOptionList {
  name: string;
  fuzzyThreshold: number;
  locales: Locale[];
  options: RawOptionListOption[];
}

export interface RawOptionListOption extends Omit<OptionListOption, 'text' | 'aliases'> {
  aliasesByLocale: { [localeName: string]: string[] };
}

export interface Bucket {
  name: string;
  isGroupBucket: boolean;
  type: string;
  options: string;
}

export interface Line {
  name: string;
  isRoot: boolean;
  isGlobal: boolean;
  isWrapUpLine: boolean;
  tags: string[];
  categories: string[];
}
