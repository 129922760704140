import { GraphLink, GraphNode } from '../components/enquiry-line-graph/types';
import { QuestionType } from './enquiry.dto';

export interface BranchInformation {
  name: string;
  parent: string;
  commitId: string;
  isPublic: boolean;
}

export interface EditorConfiguration {
  chainingEnabled: boolean;
}

export interface Asset {
  name: string;
}

export interface QuestionAsset extends Asset {
  categories: string;
}

export interface LocaleAsset extends Asset {
  isDefault: boolean;
}

export interface LineAsset extends Asset {
  isRoot: boolean;
  isWrapUp: boolean;
}

export interface QuestionDefinition {
  name: string;
  type: QuestionType;
  tags: string;
  isGlobal: boolean;
  isMultiValued: boolean;
  textByLocale: Record<string, string>;
  helpTextByLocale: Record<string, string>;
  categories: string;
}

export interface FreeTextQuestionDefinition extends QuestionDefinition {
  maxLength: number;
}

export function isFreeText(question: QuestionDefinition | undefined): question is FreeTextQuestionDefinition {
  return question?.type === QuestionType.STRING;
}

export interface NumberQuestionDefinition extends QuestionDefinition {
  lowerBound: number;
  upperBound: number;
}

export function isNumberQuestion(question: QuestionDefinition | undefined): question is NumberQuestionDefinition {
  return question?.type === QuestionType.NUMBER;
}

export interface IntegerQuestionDefinition extends NumberQuestionDefinition {}

export function isIntegerQuestion(question: QuestionDefinition | undefined): question is IntegerQuestionDefinition {
  return question?.type === QuestionType.INTEGER;
}

export enum DateRoundingPreference {
  Latest = 'Latest',
  Earliest = 'Earliest',
  Nearest = 'Nearest',
  Furthest = 'Furthest',
  None = 'None',
}

export function isDateQuestion(question: QuestionDefinition | undefined): boolean {
  return question?.type === QuestionType.DATE;
}

export enum RelativeDateRoundingPreference {
  Latest = 'Latest',
  Earliest = 'Earliest',
  None = 'None',
}

export function isPastDateQuestion(question: QuestionDefinition | undefined): boolean {
  return question?.type === QuestionType.PAST_DATE;
}

export function isFutureDateQuestion(question: QuestionDefinition | undefined): boolean {
  return question?.type === QuestionType.FUTURE_DATE;
}

export interface ShortListQuestionDefinition extends QuestionDefinition {
  excludedCategories: string;
  includedCategories: string;
  options: string;
}

export function isShortListQuestion(question: QuestionDefinition | undefined): question is ShortListQuestionDefinition {
  return question?.type === QuestionType.OPTION_GROUP;
}

export interface MediumListQuestionDefinition extends ShortListQuestionDefinition { }

export function isMediumListQuestion(question: QuestionDefinition | undefined): question is MediumListQuestionDefinition {
  return question?.type === QuestionType.OPTION_LIST;
}

export interface LongListQuestionDefinition extends ShortListQuestionDefinition {
  minSearchChars: number;
  isLenient: boolean;
  defaultOption: string;
}

export function isLongListQuestion(question: QuestionDefinition | undefined): question is LongListQuestionDefinition {
  return question?.type === QuestionType.OPTION_BACKED;
}

export enum BucketType {
  GROUPED = 'GROUPED',
  OPTION = 'OPTION',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  ENQUIRY = 'ENQUIRY_LINE',
}

export interface BucketDefinition {
  name: string;
  type: BucketType;
}

export interface NumberBucketDefinition extends BucketDefinition {
  visibleWhen: string;
}

export function isNumberBucket(bucket: BucketDefinition | undefined): bucket is NumberBucketDefinition {
  return bucket?.type === BucketType.NUMBER;
}

export interface OptionBucketDefinition extends BucketDefinition {
  visibleWhen: string;
  options: string;
}

export function isOptionBucket(bucket: BucketDefinition | undefined): bucket is OptionBucketDefinition {
  return bucket?.type === BucketType.OPTION;
}

export interface GroupBucketDefinition extends BucketDefinition {
  groupedBuckets: string[];
}

export function isGroupBucket(bucket: BucketDefinition | undefined): bucket is GroupBucketDefinition {
  return bucket?.type === BucketType.GROUPED;
}

export interface DateBucketDefinition extends BucketDefinition {
  visibleWhen: string;
}

export function isDateBucket(bucket: BucketDefinition | undefined): bucket is DateBucketDefinition {
  return bucket?.type === BucketType.DATE;
}

export interface EnquiryBucketDefinition extends BucketDefinition {
  visibleWhen: string;
}

export function isEnquiryBucket(bucket: BucketDefinition | undefined): bucket is EnquiryBucketDefinition {
  return bucket?.type === BucketType.ENQUIRY;
}

export interface LocaleDefinition {
  name: string;
  description: string;
  languageCode: string;
  countryCode: string;
  fallbackLocale: string;
  isDefault: boolean;
  dateFormatPreference: string;
  earliestDateValidation: string;
  latestDateValidation: string;
  futureDateValidation: string;
  pastDateValidation: string;
  invalidDateValidation: string;
  minNumberValidation: string;
  maxNumberValidation: string;
  wholeNumberValidation: string;
  invalidNumberValidation: string;
  invalidOptionValidation: string;
  maxCharsValidation: string;
}

export interface PriorEnquiryDefinition {
  name: string;
}

export interface OptionListDefinition {
  name: string;
  options: OptionDefinition[];
}

export type OptionAttributeValue = string | boolean | number;

export interface OptionDefinition {
  order: number;
  name: string;
  aliasesByLocale: Record<string, string>;
  triggers: string[];
  tags: string[];
  categories: string[];
  attributes: Record<string, OptionAttributeValue>
}

export interface LineDefinition {
  name: string;
  nodes: GraphNode[];
  links: GraphLink[];
  rootNodes: string[];
  displayNameByLocale: Record<string, string>;
  preambleByLocale: Record<string, string>;
  sectionByLocale: Record<string, string>;
  tags: string[];
  categories: string[];
  isMultiTriggered: boolean;
  isRoot: boolean;
  isWrapUp: boolean;
}

export interface WrapUpLine {
  name: string;
}

export interface TableDefinition {
  name: string;
  sheetAxis?: string;
}

export interface LineTestDefinition {
  name: string;
  lineName: string;
  totalCount: number;
  passedCount: number;
  failedCount: number;
  isValid: boolean;
  hasWarnings: boolean;
  status : TestRunStatus
}

export enum TestRunStatus {
  NONE = 'NONE',
  PENDING = 'PENDING',
  PASSED = 'PASSED',
  FAILED = 'FAILED',
  INVALID = 'INVALID',
}

export interface AssetValidationError {
  target: string; // enquiryLines.Line1.nodes.node1 or buckets.bucket1.visibleWhen etc.
  messages: string[];
  parentTarget?: string
}

export interface DefinitionValidationError {
  message: string;
}

export interface EditorValidationErrors {
  enquiryDefinition?: DefinitionValidationError[];
  enquiryLines?: AssetValidationError[];
  wrapUpLines?: AssetValidationError[];
  buckets?: AssetValidationError[];
  optionLists?: AssetValidationError[];
  questions?: AssetValidationError[];
  tables?: AssetValidationError[];
  locales?: AssetValidationError[];
  priorEnquiryChannels?: AssetValidationError[];
}

export interface AssetUrlVariables {
  urlBranchName: string;
  urlLineName: string;
  urlAssetName: string;
}
