import { Result } from 'antd';
import React, {
  createContext, FC, PropsWithChildren, useEffect, useMemo, useState,
} from 'react';
import jwtDecode from 'jwt-decode';
import { useAuth0 } from '@auth0/auth0-react';
import sha1 from 'crypto-js/sha1';
import { useErrorCatcher } from '../../components/error-handler/ErrorHandler';
import Loader from '../../components/loader/Loader';
import { UserData } from '../../dtos/user.dto';
import { axiosAuthClient } from './axiosAuthClient';
import { useAuthConfig } from './auth.properties.service';
import { ErrorPage } from '../../components/error-page/ErrorPage';
import { ProfileServiceMetadata } from './api/types';
import { useMetrics } from '../metrics/MetricsProvider';
import { useMonitoring } from '../monitoring/MonitoringProvider';

export interface AuthContextData {
  user: UserData | null;
  refreshUser: () => Promise<UserData>;
  signout: VoidFunction;
}

const AuthContext = createContext<AuthContextData>(null!);

const decodeToken = (token: string) => JSON.parse(JSON.stringify(jwtDecode(token)));

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const [user, setUser] = useState<UserData | null>(null);
  const authConfigResponse = useAuthConfig();
  const { metrics } = useMetrics();
  const { connector } = useMonitoring();

  const authHeader = 'Authorization';
  const authTokenPrefix = 'Bearer';

  useEffect(() => {
    if (authConfigResponse && authConfigResponse.loaded && user) {
      // TODO Remove hashing if we don't need it
      const hashedUsername = sha1(user?.username || '').toString();
      metrics.onUserIdentified(hashedUsername, { clientId: authConfigResponse.authConfig.providerId });
    }
  }, [authConfigResponse, user]);

  const value = useMemo(() => {
    const handleUserChange = (accessToken: string) => {
      const decodedToken = decodeToken(accessToken);
      const { profile } = decodedToken['https://underwriteme.co.uk/app_metadata'];
      const profileServiceMetadata: ProfileServiceMetadata = JSON.parse(profile);
      const providerIds = (profileServiceMetadata.groups || []).flatMap((group) => (group.providers || []));

      const userData: UserData = {
        id: decodedToken['https://underwriteme.co.uk/profile-id'],
        username: decodedToken['https://underwriteme.co.uk/username'],
        permissions: decodedToken['https://underwriteme.co.uk/permissions'],
        languageTag: profileServiceMetadata.languageTag,
        providerIds,
        allowAdminAccess: profileServiceMetadata.allowAdminAccess,
        samlUser: decodedToken['https://underwriteme.co.uk/authentication_type'] === 'SAML',
      };
      connector.setUser(userData.id, userData.username);
      setUser(userData);
      axiosAuthClient.defaults.headers.common[authHeader] = `${authTokenPrefix} ${accessToken}`;
      return userData;
    };

    return ({
      user,
      refreshUser: () => getAccessTokenSilently().then(handleUserChange),
      signout: () => {
        logout({ returnTo: `${window.location.protocol}//${window.location.host}/`, client_id: authConfigResponse.authConfig?.clientId });
        setUser(null);
      },
    });
  }, [user]);

  if (!authConfigResponse.loaded) {
    return (
      <div />
    );
  } else if (authConfigResponse.error) {
    return (
      <ErrorPage />
    );
  }

  if (user) {
    if (!user?.allowAdminAccess && !user.providerIds.includes(authConfigResponse.authConfig.providerId)) {
      setUser(null);
      logout({ returnTo: `${window.location.protocol}//${window.location.host}/`, client_id: authConfigResponse.authConfig?.clientId });
    }
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);

export interface AuthGuardProps {
  permissions?: string[];
}

export const AuthGuard: FC<PropsWithChildren<AuthGuardProps>> = ({ children, permissions = [] }) => {
  const auth = useAuth();
  const catchError = useErrorCatcher();
  const [canRefresh, setCanRefresh] = useState(true);

  if (!auth.user) {
    if (canRefresh) {
      auth.refreshUser().catch(catchError).finally(() => setCanRefresh(false));
      return (
        <Result
          icon={<Loader />}
        />
      );
    }
    return auth.signout();
  } if (permissions.length && !auth.user.permissions.some((userPermission) => permissions.includes(userPermission))) {
    // TODO maybe worth adding a error page instead of forcing a signout
    return auth.signout();
  }
  return children as any;
};
